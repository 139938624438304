export function getRecentSearchTemplate(query: string) {
  const template = document.querySelector(
    "#bm-recent-search"
  ) as HTMLTemplateElement;

  const clone = template.content.cloneNode(true) as HTMLElement;
  const item = clone.querySelector(".bm-recent-search__item");
  const button = clone.querySelector(".bm-recent-search__item button");

  button.textContent = query;

  return item;
}
