export type RecentSearchType = {
  query: string;
  timestamp: number;
};

export enum EventType {
  SearchResult = "Search Result",
  SearchResultTap = "Search Result Tap",
}

export type SearchInputSource =
  | "search-bar"
  | "trending-search"
  | "recent-search";
