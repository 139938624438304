import { Controller } from "@hotwired/stimulus";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import instantsearch from "instantsearch.js";
import { searchBox, hits, index, stats } from "instantsearch.js/es/widgets";
import { localStorageHelper } from "../../javascripts/utils/localStorage";
import {
  COMMIT_RECENT_SEARCH_RESULT_WAIT_TIME,
  COURSE_FIELDS,
  LESSON_FIELDS,
  MAX_NUMBER_OF_RECENT_SEARCHES,
  PORT_NUMBER,
  RECENT_SEARCH_DEBOUNCE_VALUE,
  RECENT_SEARCH_KEY,
} from "./search/constants";

import { getRecentSearchTemplate } from "./search/helpers";

import { RecentSearchType, EventType, SearchInputSource } from "./search/types";

export default class extends Controller {
  private hiddenClass = "d-none";
  private overflowClass = "overflow-hidden";
  private courseHits = new Map();
  private lessonHits = new Map();

  static targets = [
    "hits",
    "default",
    "courseHits",
    "lessonHits",
    "trending",
    "recentList",
    "recent",
    "searchButton",
  ];

  static values = {
    config: Object,
    courseResults: {
      default: 0,
      type: Number,
    },
    lessonResults: {
      default: 0,
      type: Number,
    },
    recentSearches: {
      default: false,
      type: Boolean,
    },
  };

  declare configValue: {
    apiKey: string;
    nearestNodeHost: string;
    nodesHosts: string[];
    courseIndexName: string;
    lessonIndexName: string;
    coursePresetName: string;
    lessonPresetName: string;
  };

  declare search: any;
  declare timerId: ReturnType<typeof setTimeout>;
  declare hitsTarget: HTMLElement;
  declare defaultTarget: HTMLElement;
  declare header: HTMLHeadElement;
  declare htmlElement: HTMLElement;
  declare courseHitsTarget: HTMLElement;
  declare lessonHitsTarget: HTMLElement;
  declare courseResultsValue: number;
  declare lessonResultsValue: number;
  declare inputElement: HTMLInputElement;
  declare trendingTarget: HTMLUListElement;
  declare recentListTarget: HTMLUListElement;
  declare recentTarget: HTMLElement;
  declare recentSearchesValue: boolean;
  declare storedSearches: Array<RecentSearchType>;
  declare searchButtonTarget: HTMLButtonElement;
  declare inputSource: SearchInputSource;

  createNodes(nodesHosts: string[]) {
    return nodesHosts.map((node) => {
      return {
        host: node,
        port: PORT_NUMBER,
        protocol: "https",
      };
    });
  }

  connect() {
    this.header = document.querySelector(".bm-header") as HTMLElement;
    this.htmlElement = document.documentElement;

    // Get the recent searches from local storage
    this.storedSearches = localStorageHelper.get(RECENT_SEARCH_KEY);
    // Determine if we show the trending/recent searches list
    if (this.storedSearches !== null) {
      this.recentSearchesValue = true;
      this.displayInitialRecentSearches(this.storedSearches);
      this.trendingTarget.classList.add(this.hiddenClass);
    } else {
      this.recentTarget.classList.add(this.hiddenClass);
    }

    // Setup typesense adapter
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: this.configValue.apiKey,
        cacheSearchResultsForSeconds: 0,
        nearestNode: {
          host: this.configValue.nearestNodeHost,
          port: PORT_NUMBER,
          protocol: "https",
        },
        nodes: this.createNodes(this.configValue.nodesHosts),
      },
      collectionSpecificSearchParameters: {
        [this.configValue.courseIndexName]: {
          preset: this.configValue.coursePresetName,
          include_fields: COURSE_FIELDS,
        },
        [this.configValue.lessonIndexName]: {
          preset: this.configValue.lessonPresetName,
          include_fields: LESSON_FIELDS,
        },
      },
    });

    const { searchClient } = typesenseInstantsearchAdapter;

    this.search = instantsearch({
      searchClient,
      indexName: this.configValue.courseIndexName,
      initialUiState: {
        [this.configValue.courseIndexName]: {
          query: "",
        },
      },
      onStateChange: ({ uiState, setUiState }) => {
        const query = uiState[this.configValue.courseIndexName].query;

        if (query) {
          this.initSearchActiveState();
          setUiState(uiState);
          return;
        }
        this.removeSearchActiveState();
      },
    });

    let timerInputId = this.timerId;

    this.search.addWidgets([
      searchBox({
        container: ".searchbox",
        placeholder: "Search BBC Maestro",
        queryHook(query, refine) {
          clearTimeout(timerInputId);
          timerInputId = setTimeout(
            () => refine(query),
            RECENT_SEARCH_DEBOUNCE_VALUE
          );
        },
      }),
      stats({
        container: ".stats",
        templates: {
          text(data, { html }) {
            if (!data.hasNoResults) return null;

            return html`<div class="u-section-padding-top text-center">
              <h3 class="text-neutral-50 vc-h2">No results found</h3>
              <p class="mt-3 text-neutral-50">
                Try searching for another course, name or topic
              </p>
            </div>`;
          },
        },
      }),
      hits({
        container: ".course-hits",
        transformItems: (items, { results }) => {
          // Clear our course map to ensure current state is up to date
          this.courseHits.clear();

          this.dispatch("course-state-change", {
            detail: {
              hits: results.hits.length,
            },
          });

          this.courseResultsValue = results.hits.length;

          return items.map((item) => {
            this.courseHits.set(item.record_id, item);
            return item;
          });
        },
        templates: {
          empty() {
            return null;
          },
          item: (hit, { html, components }) =>
            html`
              <a
                class="vc-poster vc-poster--medium position-relative"
                href="${hit.path}"
                data-action="click->search#addSelectedQueryToRecentSearches click->search#trackCourseClick"
                data-hit-id="${hit.record_id}"
              >
                <div class="vc-poster__background">
                  <picture>
                    <img alt="" src="${hit.main_image.large}" />
                  </picture>
                </div>
                <div class="vc-poster__copy">
                  <h2 class="vc-h4 mb-0 text-tertiary">
                    ${components.Highlight({
                      hit,
                      attribute: "maestro.full_name",
                    })}
                  </h2>
                  <p
                    class="vc-poster__subtitle text-neutral-100 font-xs mt-2 mt-md-3 mb-0"
                  >
                    ${components.Highlight({ hit, attribute: "title" })}
                  </p>
                  <p
                    class="bm-font-weight-bold font-xs mt-2 mt-md-3 text-white text-uppercase u-letter-spacing"
                  >
                    ${hit._group_found == 1
                      ? html`${hit._group_found} Related Lesson`
                      : html`${hit._group_found} Related Lessons`}
                  </p>
                </div>

                ${hit.coming_soon && !hit.badge.new
                  ? html`<p
                      class="bm-font-weight-bold font-xs bg-neutral-900 text-neutral-50 text-uppercase vc-badge--right vc-badge"
                    >
                      ${hit.badge.text}
                    </p>`
                  : null}
                ${hit.badge.new
                  ? html`<div class="vc-badge-rotated-right-holder">
                      <p
                        class="bm-font-weight-bold text-uppercase bg-primary-500 text-white vc-badge--rotated-right font-xs vc-badge text-neutral-50 vc-badge-rotated-right"
                      >
                        New
                      </p>
                    </div>`
                  : null}
              </a>
            `,
        },
      }),
      index({ indexName: this.configValue.lessonIndexName }).addWidgets([
        hits({
          container: ".lesson-hits",
          transformItems: (items, { results }) => {
            // Clear our lesson map to ensure current state is up to date
            this.lessonHits.clear();

            if (results.query === "") return [];
            this.lessonResultsValue = results.hits.length;

            return items.map((item) => {
              this.lessonHits.set(item.lesson.id, item);
              return item;
            });
          },
          templates: {
            empty() {
              return null;
            },
            item: (hit, { html, components }) =>
              html`
                <a
                  class="vc-video-tile vc-video-tile--dark"
                  href="${hit.lesson.path}?autoplay=true#lesson-player"
                  data-action="click->search#addSelectedQueryToRecentSearches click->search#trackLessonClick"
                  data-hit-id="${hit.lesson.id}"
                >
                  <div
                    class="vc-video-tile__image-container | flex-shrink-0 position-relative"
                  >
                    <img
                      class="vc-video-tile__image | h-100 w-100"
                      src="${hit.lesson.main_image.medium}"
                      alt=""
                    />
                    <p
                      class="vc-video-tile__duration | bg-neutral-800 bm-font-weight-bold d-none d-md-inline-block font-xs mb-0 p-1 position-absolute text-neutral-50 text-uppercase u-letter-spacing"
                    >
                      ${hit.lesson.duration}${" "}
                      <abbr>${hit.lesson.duration == 1 ? "min" : "mins"}</abbr>
                    </p>
                  </div>
                  <div class="vc-video-tile__copy">
                    <h2
                      class="bm-font-weight-medium mt-1 mt-md-3 mb-0 u-line-clamp u-line-clamp--one | vc-subtitle vc-video-tile__heading"
                    >
                      ${components.Highlight({
                        hit,
                        attribute: "lesson.title",
                      })}
                    </h2>
                    <p class="mt-2 | vc-video-tile__paragraph">
                      <span class="mr-1">
                        ${components.Highlight({
                          hit,
                          attribute: "maestro.full_name",
                        })}
                      </span>
                      <span class="vc-video-tile__meta"
                        >Lesson ${hit.lesson.position}/${hit.lesson_count}</span
                      >
                    </p>
                  </div>
                  <p
                    class="align-self-center d-md-none mb-0 font-xs ml-auto text-neutral-300 text-nowrap"
                  >
                    ${hit.lesson.duration}${" "}
                    <abbr>${hit.lesson.duration == 1 ? "min" : "mins"}</abbr>
                  </p>
                </a>
              `,
          },
        }),
      ]),
    ]);

    this.search.start();

    this.search.on("render", () => {
      requestAnimationFrame(() => {
        this.dispatch("search-state-change");
      });
    });

    // Bind events to input
    this.inputElement = this.element.querySelector(".ais-SearchBox-input");
    let typingTimer: ReturnType<typeof setTimeout>;

    this.inputElement.addEventListener("input", (e: Event) => {
      const target = e.target as HTMLInputElement;
      this.inputSource = "search-bar";

      clearTimeout(typingTimer);

      typingTimer = setTimeout(() => {
        const updateList = this.addRecentSearch({
          query: target.value,
          timestamp: Date.now(),
        });

        // If we have no results, don't add the term to the recent searches list
        if (this.courseResultsValue == 0 && this.lessonResultsValue == 0)
          return;

        // If the search field is empty, don't track or add to recent searches
        if (target.value == "") return;

        this.trackSearchResult(EventType.SearchResult);
        localStorageHelper.set(RECENT_SEARCH_KEY, updateList);
        this.recentSearchesValue = true;
        this.updateRecentSearchesState(target.value);
      }, COMMIT_RECENT_SEARCH_RESULT_WAIT_TIME);
    });
  }

  recentSearchesValueChanged(value: boolean) {
    this.recentTarget.classList.toggle(this.hiddenClass, !value);
    if (value) {
      this.trendingTarget.classList.add(this.hiddenClass);
    }
  }

  addSelectedQueryToRecentSearches() {
    const value = this.search.helper.state.query;
    const newList = this.addRecentSearch({
      query: value,
      timestamp: Date.now(),
    });
    localStorageHelper.set(RECENT_SEARCH_KEY, newList);

    this.updateRecentSearchesState(value);
  }

  updateHeader({ detail: { open } }) {
    if (open) {
      this.htmlElement.classList.add(this.overflowClass);
      const searchField = this.element.querySelector(
        "input[type='search']"
      ) as HTMLInputElement;
      searchField.focus();
    } else {
      this.removeSearchActiveState();
      this.searchButtonTarget.focus();
    }
  }

  initSearchActiveState() {
    const carousel = document.querySelector("ol.ais-Hits-list");
    // When a new search is initiliased, explicitly move the carousel
    // all the way to the beginning again
    if (carousel) {
      carousel.scrollLeft = 0;
    }

    this.hitsTarget.classList.remove(this.hiddenClass);
    this.defaultTarget.classList.add(this.hiddenClass);
    this.header.style.setProperty("--header-bg-color", "#1a1a1a");
    this.header.style.setProperty("--search-color", "#fff");
    this.header.style.setProperty("--logo-fill-color", "#fff");
    this.header.style.setProperty("--hamburger-menu-color", "#fff");
    this.header.style.setProperty("--hamburger-menu-color-open", "#fff");
    this.header.style.setProperty("--nav-item-color", "#fff");
    this.header.style.setProperty("--logo-fill-color-bbc", "#fff");
    this.header.style.setProperty("--logo-fill-color-maestro", "#fff");
    this.htmlElement.classList.add(this.overflowClass);
  }

  removeSearchActiveState() {
    this.hitsTarget.classList.add(this.hiddenClass);
    this.defaultTarget.classList.remove(this.hiddenClass);
    this.header.style.removeProperty("--header-bg-color");
    this.header.style.removeProperty("--search-color");
    this.header.style.removeProperty("--logo-fill-color");
    this.header.style.removeProperty("--hamburger-menu-color");
    this.header.style.removeProperty("--hamburger-menu-color-open");
    this.header.style.removeProperty("--nav-item-color");
    this.header.style.removeProperty("--logo-fill-color-bbc");
    this.header.style.removeProperty("--logo-fill-color-maestro");
    this.htmlElement.classList.remove(this.overflowClass);
  }

  submitSearch(e: Event) {
    const target = e.target as HTMLElement;
    const value = target.textContent;
    // This will either be "recent-search" or "trending-search"
    this.inputSource = target.dataset.inputSource as SearchInputSource;

    this.search.helper.setQuery(value).search();

    this.updateRecentSearchesState(value);
    this.trackSearchResult(EventType.SearchResult);
  }

  courseResultsValueChanged(value: number) {
    this.courseHitsTarget.classList.toggle(this.hiddenClass, value == 0);
  }

  lessonResultsValueChanged(value: number) {
    this.lessonHitsTarget.classList.toggle(this.hiddenClass, value == 0);
  }

  disconnect() {
    this.htmlElement.classList.remove(this.overflowClass);
    clearTimeout(this.timerId);
    this.removeSearchActiveState();
  }

  updateRecentSearchesState(query: string) {
    if (this.doesSearchTermExist(query)) return;

    this.updateRecentSearchesList(query);

    if (this.getCurrentRecentSearches()) return;

    const searchItem = {
      query,
      timestamp: Date.now(),
    };

    localStorageHelper.set(RECENT_SEARCH_KEY, [searchItem]);
    this.recentSearchesValue = true;
  }

  displayInitialRecentSearches(searches: Array<RecentSearchType>) {
    searches.forEach((search: RecentSearchType) => {
      this.addRecentSearch({
        query: search.query,
        timestamp: search.timestamp,
      });
      const item = getRecentSearchTemplate(search.query);
      this.recentListTarget.appendChild(item);
    });
  }

  addRecentSearch({
    query,
    timestamp,
  }: RecentSearchType): Array<RecentSearchType> {
    let newRecentSearches = [];
    const recentSearches = this.getCurrentRecentSearches();

    if (query == "" && recentSearches != null) return recentSearches;

    if (recentSearches == null) {
      newRecentSearches.push({
        query,
        timestamp,
      });

      return newRecentSearches;
    }

    newRecentSearches = recentSearches.filter(
      (search: RecentSearchType) =>
        search.query !== query && !query.includes(search.query)
    );

    // If we hit the maximum amount of recent searches then remove the
    // last item
    if (newRecentSearches.length == MAX_NUMBER_OF_RECENT_SEARCHES) {
      newRecentSearches.pop();
    }

    newRecentSearches.unshift({
      query,
      timestamp,
    });

    return newRecentSearches;
  }

  getCurrentRecentSearches() {
    return localStorageHelper.get(RECENT_SEARCH_KEY);
  }

  doesSearchTermExist(query: string) {
    const listItems = this.getRecentSearchListItems();
    return listItems.some(
      (li) => li.textContent.trim().toLowerCase() === query.toLowerCase()
    );
  }

  getRecentSearchListItems() {
    return Array.from(this.recentListTarget.getElementsByTagName("li"));
  }

  updateRecentSearchesList(query: string) {
    const recentItems = this.getCurrentRecentSearches();
    const item = getRecentSearchTemplate(query) as HTMLElement;

    this.recentListTarget.innerHTML = "";

    if (!recentItems) {
      this.recentListTarget.appendChild(item);
      return;
    }

    recentItems.forEach((recentItem: RecentSearchType) => {
      const item = getRecentSearchTemplate(recentItem.query) as HTMLElement;
      this.recentListTarget.appendChild(item);
    });
  }

  trackSearchResult(eventName: EventType) {
    const properties = this.getCommonEventProperties();
    const hitCounts = this.getHitCounts();

    window.analytics.track(eventName, {
      ...properties,
      ...hitCounts,
    });
  }

  trackLessonClick(event: Event) {
    const hit = this.getHitData(event, "lessonHits");
    const properties = this.getCommonEventProperties();

    window.analytics.track(EventType.SearchResultTap, {
      type: "LESSON",
      course_title: hit.title,
      list_position: hit.__position - 1,
      maestro_name: hit.maestro.full_name,
      course_id: hit.record_id,
      lesson_id: hit.lesson.id,
      lesson_title: hit.lesson.title,
      ...properties,
    });
  }

  trackCourseClick(event: Event) {
    const hit = this.getHitData(event, "courseHits");
    const properties = this.getCommonEventProperties();

    window.analytics.track(EventType.SearchResultTap, {
      type: "COURSE",
      query: this.search.helper.state.query,
      list_position: hit.__position - 1,
      course_title: hit.title,
      maestro_name: hit.maestro.full_name,
      course_id: hit.record_id,
      ...properties,
    });
  }

  getHitData(event: Event, type: string) {
    const target = event.target as HTMLElement;
    const hitId = Number(target.closest("a").dataset.hitId);
    return this[type].get(hitId);
  }

  getCommonEventProperties() {
    const query = this.search.helper.state.query;

    return {
      query,
      platform: "web",
      user_id: Number(window.analytics.user().id()),
      input_source: this.inputSource,
    };
  }

  getHitCounts() {
    const num_hits_courses =
      this.search.renderState[this.configValue.courseIndexName].hits.results
        .nbHits;
    const num_hits_lessons =
      this.search.renderState[this.configValue.lessonIndexName].hits.results
        .nbHits;

    return {
      num_hits: num_hits_courses + num_hits_lessons,
      num_hits_lessons,
      num_hits_courses,
    };
  }
}
